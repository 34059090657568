// search filter


.card-template{
    width: 320px;
    min-height: 180px;
    height: 100%;
    // background-image: url('../../images/Background.png');
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.chooseBgc{
    border: 0 !important;
}
.chooseBgc.ant-radio-button-wrapper:not(:first-child)::before{
    display: none;
}
.ant-radio-button-wrapper-checked {
    // opacity: 0.7 !important;
    transform: scale(1.1);
    padding: 10px
}

.chooseBgc .ant-radio-button-checked {
    border-radius: 8px;
    outline: 0;
    opacity: 0.5 !important;
    -webkit-box-shadow: 0 0 0 2px #ffffff;
}

.organization-account{
    height: auto !important;

    .ant-menu-title-content{
        display: flex;
        align-items: center;
        gap: 30px;
    }
    
}

.listUserProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
   height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;

    .ant-radio-wrapper{
        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
        border-bottom: 1px solid #f0f0f0;
        align-items: flex-start;
    }
    .ant-radio-wrapper:first-child{
        padding-top: 0px;
    }
    .ant-radio-wrapper:last-child{
        padding-bottom: 0px;
        border: none;
    }
}


.modalImport{
    width: 700px !important;

    .ant-space-item{
        width: 50%;

        .ant-card-cover{
            padding-top: 20px;
            width: 210px;
            height: 112px;
            margin: auto;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .ant-card-meta{
            justify-content: center;
        }
    }

    // .ant-space
    .listOptionImport{
        height: auto !important;

        .optionImport{
            // display: flex;
            // align-items: flex-start;
            // justify-content: center;
        }
    }
}
