// Background Colors
$background-primary: rgba(0, 39, 102, 1);
// Text color
$color-primary: #0a66c2;
$color-text-hover: #0b72d9;

$primary-color: #0a66c2;
$text-color: #121a21;
$text-color-gray: #506270;

//..
$white-1: #ffffff;
$white-2: #fafafa;

//..
$black-1: #121a21;

//..
$gray-1: #d9dde0;
$gray-2: #eef0f2;
$gray-3: #506270;

//..
$blue-1: #0a66c2;
$blue-2: rgba(0, 128, 255, 0.05);

//Font Size
$size-6: 6px;
$size-8: 8px;
$size-10: 10px;
$size-11: 11px;
$size-12: 12px;
$size-13: 13px;
$size-14: 14px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-26: 26px;
$size-28: 28px;
$size-30: 30px;
$size-32: 32px;
$size-36: 36px;
$size-38: 38px;
$size-50: 50px;
$size-100: 100px;

//..Font weight
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 900;
