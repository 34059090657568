// table
.customTable table tr:nth-child(even) td {
    background-color: #FAFAFA;
}

.ant-layout.ant-layout-has-sider{
    background-color: #ffffff;
}

// upload
.upload_logo{

    .ant-upload.ant-upload-select.ant-upload-select-picture-card{
        width: 86px !important;
        height: 86px !important;
    }
}
.upload_banner{

    .ant-upload.ant-upload-select.ant-upload-select-picture-card{
        width: 294px !important;
        height: 86px !important;
    }
}

.ant-upload-image{

    .ant-upload.ant-upload-select.ant-upload-select-picture-card{
        width: 100%;

        .ant-image{
            width: 100%;
            height: 100%;

            .ant-image-img{
                height: 100%;
                width: 100%;
            }
        }
        
    }
}

// tabs
.ant-tabs-tab{
    &:hover{
        color: $color-primary !important;
    }
}
.ant-tabs-nav{
    margin-bottom: 0 !important;
}

// input
.ant-input.ant-input-lg,.ant-input-search,.ant-input-search-button {
    
    &:hover{
        border-color: $color-text-hover !important;
    }
    &:focus{
        border-color: $color-primary !important;
    }
  }
.ant-btn-icon-only.ant-input-search-button{
    &:hover{
        border-color: $color-text-hover !important;
    }
}
.ant-picker-input.ant-picker-input-active{
    &:hover{
        border-color: $color-text-hover !important;
    }
}
.ant-picker.ant-picker-range{
    &:hover{
        border-color: $color-text-hover !important;
    }
}
.ant-picker-focused{
    &:hover{
        border-color: $color-text-hover !important;
    }
}
.ant-menu-submenu-selected{
    color: white
}
.ant-input-status-error{
    background: #fff !important;
    border-color: #ff4d4f !important;

}

// .ant-input-group-addon{
//     border-top-left-radius: 8px;
//     border-bottom-left-radius: 8px;
// }
// .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button{
//     border-top-right-radius: 8px;
//     border-bottom-right-radius: 8px;
// }

// .ant-select-selector {
//     border-radius: 8px !important;
// }

// .ant-modal-footer{
//     padding: 0px 16px 24px 16px;
//     border: 0
// }

.ant-btn-primary{
    padding: 5px 16px;
    font-weight: 500;
    background: #0A66C2;
    // border-radius: 16px;
    border: 0;
    outline: 0;
    color: white;
    cursor: pointer;

    &:hover{
        background-color: #0B72D9
    }

}

.ant-btn-link{
    color: $color-primary;

    // &:hover{
    //     color: $color-text-hover
    // }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color-primary;
    text-shadow: 0 0 0.25px currentcolor;
}

// pagination
.ant-pagination-item.ant-pagination-item-active{
    border-color: $color-primary;

    a{
        color: $color-primary;

        &:hover{
            color: $color-text-hover
        }
    }
}

.ant-pagination-item{

        &:hover{
            a{
                color: $color-text-hover
            }
        }
   
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $color-text-hover;
}