//Responsive
$screen-xs: 576px;
$screen-xss: 374px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1500px;

@mixin breakpoint($point) {
    @if $point == xss {
      @media (max-width: $screen-xss) {
        @content;
      }
    } @else if $point == xs {
      @media (max-width: $screen-xs) {
        @content;
      }
    } @else if $point == min-xs {
      @media (min-width: $screen-xs) {
        @content;
      }
    } @else if $point == sm {
      @media (min-width: $screen-sm) {
        @content;
      }
    } @else if $point == max-xs {
      @media (max-width: $screen-sm) {
        @content;
      }
    } @else if $point == md {
      @media (min-width: $screen-md) {
        @content;
      }
    } @else if $point == lg {
      @media (min-width: $screen-lg) {
        @content;
      }
    } @else if $point == max-lg {
        @media (max-width: $screen-lg) {
          @content;
        }
    } @else if $point == xl {
      @media (min-width: $screen-xl) {
        @content;
      }
    } @else if $point == max-sm {
      @media (max-width: ($screen-md - 1)) {
        @content;
      }
    } @else if $point == max-md {
      @media (max-width: ($screen-lg - 1)) {
        @content;
      }
    } @else if $point == only-sm {
      @media (min-width: $screen-sm) and (max-width: ($screen-md - 1)) {
        @content;
      }
    } @else if $point == only-md {
      @media (min-width: $screen-md) and (max-width: ($screen-lg - 1)) {
        @content;
      }
    } @else if $point == only-xl {
      @media (min-width: $screen-md) and (max-width: ($screen-xl - 1)) {
        @content;
      }
    } @else if $point == max-lg {
      @media (max-width: 1325px) and (min-width: ($screen-sm - 1)) {
        @content;
      }
    }
  }


.form__login {
    width: 460px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    height: auto;
    border-radius: 5px;
    padding: 20px;
}
.btn__login.ant-btn-primary{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5574fe;
    // background-color: $background-primary;
    color: white;
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
    padding: 20px;
    font-weight: 500;
    overflow: hidden;
    border: none;
    &:hover{   
        opacity: 0.8 !important;
    }
}
.ant-input-otp{
    height: 40px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #ccc !important;
}
.ant-input-otp.ant-input-number-focused{
    border: none;
    box-shadow: none;
}

.ant-input-number-handler-wrap{
    display: none;
}
.ant-input-number-input-wrap {

    & input{
        text-align: center;
        border: none;
        outline: none;
    }
    & input[type="number"]{
        font-size: 24px;
    }   

}

// login
.login_section.ant-row{
    margin: 0 !important; 
}

.login{
    min-height: calc(100vh - 172px);
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    margin-top: 172px;
    // margin-top: 40px;
    // margin-bottom: 32px;

    .login_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 384px;
      margin-left: auto;
      margin-right: auto;
  }

 
}
.form_login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 384px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 56px;

   .login_main{
    .login_desc{
        margin-top: 40px;
        font-size: 20px;
        line-height: 28px;
        color: rgba(5, 30, 51, 0.7);
    }
    .login_title{
        font-size: 20px;
        line-height: 28px;
    }
   }

    .login_footer{
        margin-top: 31px;
       
        .line{
            width: 311px;
            height: 1px;
            background-color: #E0E6EB;
            border-radius: 2px;
        }

        .login_options{
            // width: 80%;
            margin-right: auto;
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;

            button{
                border: none;
                outline: none;
                padding: 0;
            }
        }
    }
}

.footer_text{
  font-size: 12px;
  text-align: center;
  color: rgba(5, 30, 51, 0.7);
  position: absolute;
    left: 0;
    bottom: 32px;
    width: 100%;
  // margin-top: auto;

  .term_policy{
    color: rgba(5, 30, 51, 0.7);
    text-decoration:underline;
  }
}

.login_left{
  position: relative;
}

.login_right{

    .login_image{
      position: fixed;
      width: 50%;
      min-height: 100vh;

      img{
        width: 100%;
        min-height: 100vh;
        object-fit: cover;
      }
    }
    .dropdownLanguage{
      top: 32px;
      position: absolute;
      cursor: pointer;
      gap: 8px;
      z-index: 2;
      right: 32px;
    }
}

.btn-social{
  background-color: #E0E5EB;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
}
.btn-back{
    display: block;
    color:#121A21;
    padding: 0;
    margin-top: 40px;
    // margin-bottom: 24px;
    font-weight: 500;
}
.btn-primary{
  background-color: #0A66C2;
  color: white
}
.btn-otp{
    color:#0A66C2;
    padding: 0;
}
.btn-resend{
    color:#0A66C2;
    padding: 0;
    font-weight: 500;
    display: block;
    padding-left: 12px;

    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 25px;
        background-color: #051E33; 
        opacity: 0.2;
        left: 0;
        bottom: 0;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}

.react-tel-input,.react-tel-input .form-control  {
  border-radius: 2px !important;
    &:hover {

       .form-control {
            border-color: #40a9ff !important;
        }
        .flag-dropdown {
            border-color: #40a9ff !important;
        }
    }
}

.form-control {
    height: 40px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    // border-radius: 8px !important;
    width: 100% !important;
    min-width: 0 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff !important;
    background-image: none;
    border: 1px solid #d9d9d9 !important;

}
.flag-dropdown {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
    border-right: 0px !important;
    padding-right: 2px !important;
    background-color: #fff !important;
}

.selected-flag{
    position: relative;
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;

    ::before{
        position: absolute;
        content: "";
        right: 0;
        display: block;
        width: 1px;
        height: 1px;
        background-color: red;
    }
}

.react-tel-input .flag-dropdown.open{

    &:hover{
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
    }
   
}

.react-tel-input .selected-flag{

    &:hover{
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
    }
}

// border-color: #40a9ff;
