.pageHeader{
    // max-width: 396px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    h4{
        margin: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}