.loading-page{
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    left: 0;
    background-color: $background-primary
}

.loading{
    position: absolute;
    left: 50%;
    margin-top: 30px;
}

#logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    // animation: fill 0.1s ease forwards 1.5s;
}

#logo path:nth-child(1){
    stroke-dasharray: 549.482421875;
    stroke-dashoffset: 549.482421875;
    animation: line-anim 1s ease forwards 0.3s, fill 0.5s linear forwards 0.2s;

}
#logo path:nth-child(2){
    stroke-dasharray: 557.81884765625;
    stroke-dashoffset: 557.81884765625;
    animation: line-anim 1s ease forwards 0.6s, fill 0.5s linear forwards 0.4s;
}
#logo path:nth-child(3){
    stroke-dasharray: 538.6406860351562;
    stroke-dashoffset: 538.6406860351562;
    animation: line-anim 1s ease forwards 0.9s, fill 0.5s linear forwards 0.8s;
}
#logo path:nth-child(4){
    stroke-dasharray: 540.251953125;
    stroke-dashoffset: 540.251953125;
    animation: line-anim 1s ease forwards 1.2s, fill 0.5s linear forwards 1s ;
}
#logo path:nth-child(5){
    stroke-dasharray: 366.1220703125;
    stroke-dashoffset: 366.1220703125;
    animation: line-anim 1s ease forwards 1.5s, fill 0.5s linear forwards 1.2s;
    
}

@keyframes line-anim {
    to{
        stroke-dashoffset: 0;
    }
}

@keyframes fill {
    from{
        fill: transparent;
    }
    to{
        fill: white;
    }
}
