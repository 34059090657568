@import "common/variables";
@import "common/customAnt";

.button {
  padding: 5px 16px;
  font-weight: 500;
  background: #0a66c2;
  // border-radius: 16px;
  border: 0;
  outline: 0;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0b72d9;
  }
}

.container {
  padding: 24px;
}
.bgWhite {
  background-color: #fff !important;
}
// font
.regular {
  font-weight: 400 !important;
}
.semibold {
  font-weight: 500 !important;
}
.capitalize {
  text-transform: capitalize !important;
}

// layout
.flexGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexGroup2 {
  display: flex;
  align-items: center;
}
.d-flex {
  display: flex;
  align-items: center;
}
.dflex-start {
  display: flex;
  align-items: flex-start;
}

.space-between {
  justify-content: space-between !important;
}
.border-none {
  border: none;
}
.h-full {
  height: 100% !important;
}
.w-full {
  width: 100% !important;
}

// margin
.m-0 {
  margin: 0px !important;
}
// -------------------------------
.mt-0 {
  margin-top: 0 !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
// -------------------------------
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
// -------------------------------
.ml-0 {
  margin-left: 0 !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-8 {
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
// -------------------------------
.mr-0 {
  margin-right: 0 !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 30px !important;
}

// padding
.p-0 {
  padding: 0px !important;
}
// -----------------------------
.pt-0 {
  padding-top: 0px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
// ------------------------------
.pr-0 {
  padding-right: 0px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
// ------------------------------
.pl-0 {
  padding-left: 0 !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
// ------------------------------
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
