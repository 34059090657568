.listPost {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.post {
  max-width: 400px;
  margin-bottom: 16px;
  // border-radius: 12px;
  border: 1px solid $gray-1;
  padding: 12px 0;
  &Header {
    padding: 0 12px;
  }
  .postContent {
    &__text {
      max-width: 400px;
      a {
        // display: block;
        margin-left: 0;
        color: $blue-1;
      }
      // overflow: hidden;
      // text-overflow: ellipsis;
      // -webkit-line-clamp: 2;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
    }

    // &__text.enable {
    //   overflow: visible;
    //   -webkit-line-clamp: unset;
    //   margin-bottom: 16px;
    // }

    &__img {
      margin-top: 8px;
      max-width: 400px;
      width: 100%;
      height: 100%;
      cursor: pointer;

      .ant-image-mask {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__link {
      margin-top: 16px;
      display: block;
      overflow: hidden;
      border-top: 1px solid $gray-1;

      &__top {
        max-width: 400px;
        height: 176px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__bottom {
        background-color: $gray-2;
        padding: 4px 16px;

        h3 {
          color: $gray-3;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 0;
          font-weight: 400;
        }
        h4 {
          margin-bottom: 0;
        }
      }
    }
  }

  .totalReaction {
    display: flex;
    margin-top: 8px;
    padding: 0 16px;
  }
}
