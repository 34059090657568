.sidebar{
    overflow: hidden !important;
    background-color: $background-primary !important;
    min-height: 100vh;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    z-index: 9;
}

.sidebar .ant-menu{
    background-color: $background-primary !important;
    color: white

}

// .sidebarTeams .ant-menu{
//     background-color: #003049 !important;
//     color: white
// }
.ant-menu-submenu-selected{
    color: white
}

.sidebar__btn{
    padding: 0 16px;
    height: 40px;
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #F0F0F0;

    & button{
        border: none;
        outline: none;
    }
}
.sidebar__active {
    background-color: rgba(0, 64, 128, 1);
    width: 100%;
    transition: 0.3s;
        &::before{
            display: block;
            content: "";
            width: 3px;
            height: 100%;
            position: absolute;
            right: 0;
            background-color: white;
        }
  }

.ant-menu-light .ant-menu-item:hover{
    color: white;
}
.ant-menu-light .ant-menu-submenu-title:hover{
    color: white;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
    color: white
}
.ant-menu-light .ant-menu-submenu-active{
    color: white
}

.active.ant-menu-item {
    background-color: unset;
    &::after{
        border-right: 3px solid white !important;
    }
  }

  .active.ant-menu-item-selected {
    color: white;
}

.active.ant-menu-item-selected {
        // background-color: rgba(0, 64, 128, 1) !important;
  }

  .sidebar__logo{
    padding: 0 16px ;
    padding-top: 10px;
  }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(0, 64, 128);
}

.ant-menu-sub.ant-menu-vertical{
    background-color: $background-primary;
}

.ant-menu-submenu-arrow{
    color: white;
}
.active .ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item-selected.ant-menu-item-only-child{
    background-color: rgba(0, 64, 128, 1);

    &::after{
        border-right: 3px solid white !important;
    }
}