.header{
    height: 48px;
    background-color: white;
    padding: 0px 16px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
}

.header_title{
    max-width: 396px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    h3{
        margin: 0;
        line-height: 20px;
        max-width: 380px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.listOrg_title{
    width: 380px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    h3{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        line-height: 20px;
    }
}

.account{
    cursor: pointer;
}

.list_org_admin{
    max-height: 320px;
    overflow: auto;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;

    

    li{
        margin: 0 !important;
        padding: 8px 0px !important;

       //#f5f5f5
        .org_item{
            display: flex;
            align-items: center;
            justify-content: flex-start;

        }
        &:hover{
            background-color:#f5f5f5 !important;
            color: unset !important
        }
        &:not(:last-child){
            padding-top: 8px !important;
            margin-bottom: 0 !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }
    }

    .active{
        background-color: #f5f5f5;
    }
}
