@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

p{
  margin-bottom: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(18, 26, 33, 1);
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}


.chooseStyle .ant-form-item-row{
  align-items: flex-start;
} 

.chooseColor .ant-form-item-row{
  align-items: flex-start;
}

.linkDetail{
  color: #0A66C2 !important
}