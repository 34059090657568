@mixin generateSpacing($multiples) {
    @for $i from 0 through $multiples {
      .margin-#{$i} {
        margin: $i + px;
      }
      .ml-#{$i} {
        margin-left: $i + px;
      }
      .mr-#{$i} {
        margin-right: $i + px;
      }
      .mt-#{$i} {
        margin-top: $i + px;
      }
      .mb-#{$i} {
        margin-bottom: $i + px;
      }
      .padding-#{$i} {
        padding: $i + px;
      }
      .pl-#{$i} {
        padding-left: $i + px;
      }
      .pr-#{$i} {
        padding-right: $i + px;
      }
      .pt-#{$i} {
        padding-top: $i + px;
      }
      .pb-#{$i} {
        padding-bottom: $i + px;
      }
      $i: $i + 4;
    }
  }